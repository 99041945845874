<template>
  <!--自定义tab组件-->
  <el-dialog
    title="自定义选项卡"
    :visible.sync="dialogVisible"
    width="30%"
  >
      <el-form :model="tabObj">
          <el-form-item label="选项卡标签">
              <el-input v-model="tabObj.name"></el-input>
          </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="comfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "customTab",
  data() {
    return {
        dialogVisible:false,
        tabObj:{
            id:'',
            name:''
        }
    }
  },
  methods:{
    open(tabItem) {
        this.dialogVisible = true
        this.tabObj = JSON.parse(JSON.stringify(tabItem))
    },
    comfirm() {
        // 给父组件consoleMenu 发射事件并把修改后的值传过去
        this.$emit('comfirm', this.tabObj)
        this.dialogVisible = false
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
    ::v-deep .el-dialog__header {
        padding: 20px 20px 10px !important;
        border-bottom: 1px solid #DCDCDC;
    }
    ::v-deep .el-dialog__body {
        padding: 10px 36px !important;
    }
}

</style>
