<template>
  <!-- 开发人员：张亚欣 -->
  <div class="box">
    <div style="background: rgb(250, 250, 249)" v-if="$route.meta.isShowLeftList == false ? false : true">
      <div style="position: relative; height: 100%; min-width: 12px">
        <div class="icon-box" @click="toggleLeft">
          <i class="el-icon-caret-left icon" v-show="isShow"> </i>
          <i class="el-icon-caret-right icon" v-show="!isShow"> </i>
        </div>

        <div class="left-content" v-show="isShow">
          <!-- 切换视图 -->
          <choose-view
            class="pull-left choose-view"
            :object-name="tabName"
            :objId="objId"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :tabStyle="tabStyle"
            @changeView="changeView"
            v-if="this.viewLength > 0"
          >
          </choose-view>

          <!-- 数据列表说明文字 -->
          <statistics
            :total="totalSize"
            :field="sortFieldName"
            :isQueryCount="isQueryCount"
            :time-interval="timeInterval"
            :show-checked-length="false"
            @refresh="refresh"
          >
          </statistics>
          <!-- 视图相关操作按钮 -->
          <view-buttons
            class="pull-right viewButtons"
            :show-buttons="showButtons"
            :objId="objId"
            isActive="multiScreen"
            :multi-screen="true"
            @boardView="boardView"
            @planView="planView"
            @tableView="tableView"
          >
          </view-buttons>

          <!-- 搜索框 -->
          <search-wrapper
            width="calc(50% - 10px)"
            class="searchWrapper pull-left"
            @changeKeyWord="changeKeyWord"
            :exact-search-flag="exactSearchFlag"
            :exact-search-field-list="exactSearchFieldList"
          >
          </search-wrapper>

          <!-- 列表信息 -->
          <div class="sort-content">
            <!-- 排序依据: -->
            {{ $t("label.orderby") }}
            <span>
              {{ !sortFieldName ? "" : sortFieldName }}
              <svg
                class="icon icon-sort"
                aria-hidden="true"
                :style="{
                   transform: sortDir === 'asc' ? '' : 'rotate(180deg)',
                }"
              >
                <use href="#icon-icon_sort_top"></use>
              </svg>
            </span>
          </div>
          <Wang-skeleton
            type="line"
            :options="skeletonOptions"
            v-if="pageNum === 1"
          >
          </Wang-skeleton>
          <ul
            class="dataList pull-left"
            @scroll="scrollDataList"
            v-if="pageNum !== 1"
          >
            <li
              v-for="item in pageObj.dataList"
              :key="item.id"
              class="data-box pull-left"
              @click="showDetail(item)"
            >
              <div
                class="data-item pull-left"
                :class="{ 'text-align-right': index % 2 !== 0 }"
                v-for="(field, index) in headLabelList"
                :key="field.schemefieldName"
                :style="{
                  color: index === 0 ? '#080707' : '#666',
                  clear: index === 2 ? 'both' : '',
                }"
              >
                <!-- 标签 -->
                <span
                  class="cannotEdit"
                  v-if="
                    field.schemefieldType === 'X' &&
                    field.apiname === 'cloudcctag'
                  "
                >
                  <el-tag
                    v-for="tag in item['cloudcctagList']"
                    :key="tag.name"
                    :type="
                      tag.color === 'lan'
                        ? ''
                        : tag.color === 'hong'
                        ? 'danger'
                        : tag.color === 'lv'
                        ? 'success'
                        : tag.color === 'hui'
                        ? 'info'
                        : tag.color === 'huang'
                        ? 'warning'
                        : ''
                    "
                    effect="dark"
                    class="tag"
                  >
                    {{ tag.name }}
                  </el-tag>
                </span>
                <!-- 潜在客户打分 -->
                <span
                  v-else-if="field.schemefieldName === 'leadscore'"
                  class="cannotEdit"
                >
                  <span
                    style="
                      float: left;
                      width: 20px;
                      margin-left: 14px;
                      display: inline-block;
                    "
                  >
                    {{ item[`${field.schemefieldName}`] }}
                  </span>
                  <el-progress
                    :percentage="
                      item[`${field.schemefieldName}`] !== undefined &&
                      item[`${field.schemefieldName}`] !== null
                        ? Number(item[`${field.schemefieldName}`])
                        : 0
                    "
                    :color="customColors"
                    :format="formatProgress"
                  >
                  </el-progress>
                </span>
                <!-- 评分 -->
                <span
                  v-else-if="field.schemefieldType === 'SCORE'"
                  class="cannotEdit stars"
                  style="display: contents"
                >
                  <span
                    v-for="(star, idx) in Number(field.schemefieldLength) + 1"
                    v-show="idx !== 0"
                    :class="{
                      show2: idx <= Number(item[`${field.schemefieldName}`]),
                    }"
                    :key="idx"
                  >
                  </span>
                </span>
                <!-- 查找、主详、选项列表 -->
                <span
                  v-else-if="
                    field.schemefieldType === 'Y' ||
                    field.schemefieldType === 'M' ||
                    field.schemefieldType === 'R' ||
                    field.schemefieldType === 'MR'
                  "
                >
                  {{ item[`${field.apiname}ccname`] }}
                </span>
                <!-- 日期 -->
                <span v-else-if="field.schemefieldType === 'D'">
                  {{
                    item[`${field.schemefieldName}`] | dateFormat(countryCode)
                  }}
                </span>
                <!-- 日期时间 -->
                <span v-else-if="field.schemefieldType === 'F'">
                  {{
                    item[`${field.schemefieldName}`]
                      | datetimeFormat(countryCode)
                  }}
                </span>
                <!-- 复选框 -->
                <span v-else-if="field.schemefieldType === 'B'">
                  {{
                    item[`${field.schemefieldName}`] === "true"
                      ? "是"
                      : item[`${field.schemefieldName}`] === "false"
                      ? "否"
                      : ""
                  }}
                </span>
                <span v-else>
                  {{ item[`${field.schemefieldName}`] }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="flex: 1; padding: 0; overflow: auto">
      <!-- 导航样式为控制台样式  是的话显示-->
      <!-- 二级菜单 -->
      <div
        class="topContent"
        ref="topContentRef"
        v-if="$store.state.navigationStyle && aaaCopy.length > 0"
      >
        <div class="topContent_left">
          <!-- 菜单部分 -->
          <div class="data_menu" ref="datamenu">
            <!-- 展示的tab部分 -->
            <div class="tabWrapper" ref="tabWrapper" v-if="aaaCopy.length > 0">
              <vuedraggable
                v-model="aaaCopy"
                @end="tabDragEnd"
                animation="300"
                style="display: flex"
                id="twoBox"
              >
                <div
                  v-for="(item, index) in aaaCopy"
                  :key="item.id"
                  class="each_menu"
                  :ref="`alltabs${index}`"
                  :class="item.id == currentLevel2Id ? 'cur-menu' : ''"
                >
                  <!-- <span>
                    <a @click="clickTab(item)">
                      {{ item.name }}
                    </a>
                  </span> -->
                  <span ref="remarkFather">
                    <el-tooltip
                      placement="bottom-start"
                      effect="light"
                      :visible-arrow="false"
                      :disabled="isShowTooltip"
                      popper-class="menu-tooltip"
                    >
                      <a @click="clickTab(item)" slot="content">
                        {{ item.name }}
                      </a>
                      <a
                        @mouseover="onMouseOver(item.name,index)"
                        @click="clickTab(item)"
                      >
                        {{ item.name }}
                      </a>
                    </el-tooltip>
                  </span>
                  <!-- 因为二级tab中有当前选中的一级tab项，此一级tab不展示下拉和关闭按钮按钮-->
                  <!-- v-if="item.tabAction && item.tabAction.length > 0" -->
                  <el-dropdown
                    trigger="click"
                    style="display: flex"
                    v-if="item.id != consoleData.data.level1Id"
                  >
                    <!-- 因为二级tab中有当前选中的一级tab项，一级tab时不展示这俩按钮-->
                    <!-- tab下拉操作按钮 -->
                    <i
                      v-if="item.tabAction.length > 0"
                      class="el-icon-arrow-down el-icon--right"
                      style="margin-right: 5px"
                    ></i>
                    <!-- 关闭按钮  没有isShowClose或者isShowClose为true的时候都要显示 为false的时候不展示-->
                    <i v-if="item.isShowClose != false" class="el-icon-close" @click="closeTab(item, index)"></i>
                    <el-dropdown-menu
                      slot="dropdown"
                      style="
                        white-space: nowrap;
                        width: 200px;
                        max-height: 310px;
                        border: 0;
                      "
                    >
                      <div class="more-menus-fixed menus-show-all">
                        <el-dropdown-item
                          v-for="(i, idx) in item.tabAction"
                          :title="i.name"
                          :key="idx"
                          :style="{
                            display: 'flex',
                            fontSize: '14px',
                            padding: '0px',
                          }"
                          @click.native="clickTabHandle(item, index, i, idx)"
                        >
                          <span style="padding-left: 16px" class="link-dropdown-style">
                            <a style="min-width: 140px; overflow: hidden">
                              {{ i.name }}</a
                            >
                          </span>
                        </el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </vuedraggable>
              <!-- 更多tab -->
              <el-dropdown
                v-if="isShowMore"
                trigger="click"
                class="moreMenus"
                placement="bottom"
              >
                <p class="more-menusbox" ref="moreTabs">
                  <span style="font-size: 14px">{{ $t("label.more") }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </p>
                <el-dropdown-menu
                  slot="dropdown"
                  class="mymore-menus"
                  style="
                    white-space: nowrap;
                    width: 200px;
                    max-height: 310px;
                    margin: 0;
                    border: 0;
                    top: 77px !important;
                  "
                >
                  <div
                    class="more-menus-fixed menus-show-all"
                    v-if="moreTabLists.length > 0"
                  >
                    <el-dropdown-item
                      :title="item.name"
                      v-for="(item, index) in moreTabLists"
                      :key="index"
                      @click.native="clickmoreTabs(item, index)"
                      :style="{
                        display: 'flex',
                        fontSize: '14px',
                        padding: '0px',
                      }"
                    >
                      <a
                        class="link-dropdown-style"
                        style="
                          min-width: 140px;
                          overflow: hidden;
                          position: relative;
                        "
                      >
                        <span
                          style="display: block;width:155px;overflow:hidden;margin-left: 16px"
                          :style="{
                            color:
                              item.id == currentLevel2Id
                                ? '#5789fd'
                                : '#333333',
                          }"
                        >
                          {{ item.name }}
                        </span>
                        <img
                          v-if="item.id == currentLevel2Id"
                          src="../home/assets/images/menuActive.png"
                          style="position: absolute; top: 30%; right: 10px"
                        />
                      </a>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content" ref="cacheContainer"  :class="aaaCopy.length > 0 ? 'right-content1' : ''">
        <!--        <div v-if="noDetailt" style="height: 100%; background: #fff">-->
        <!--          <div v-if="noDetailt" class="noDetailt">-->
        <!--            <svg class="icon icon-noData" aria-hidden="true">-->
        <!--              <use href="#icon-email4"></use>-->
        <!--            </svg>-->
        <!--            <p style="margin: 0">-->
        <!--              &lt;!&ndash; 未选择记录 &ndash;&gt;-->
        <!--              {{ $t("vue_label_SMS_noselected") }}-->
        <!--            </p>-->
        <!--            <p>-->
        <!--              &lt;!&ndash; 从列表中打开,以开始使用 &ndash;&gt;-->
        <!--              {{ $t("vue_label_commonobjects_view_open_from_list") }}-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </div>-->
        <keep-alive max="10">
          <!--<router-view-->
          <!--v-if="$route.meta.keepAlive"-->
          <!--:key="$route.params.pageId"-->
          <!--/>-->
          <router-view
            v-if="$route.meta.keepAlive"
            :key="$route.fullPath"
            ref="routerView"
          />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive && $route.name != 'consoleDispatchingUnits'" :key="$route.fullPath"/>
        <!--解决派工平台点击刷新选项卡没刷新的问题-->
        <router-view v-if="!$route.meta.keepAlive && $route.name == 'consoleDispatchingUnits'" :key="$route.fullPath"/>
        <!-- <keep-alive>
          <router-view></router-view>
        </keep-alive> -->


        <!--        &lt;!&ndash; 数据详情 - 通用对象详情&ndash;&gt;-->
        <!--        <detail-->
        <!--          ref="detail"-->
        <!--          style="padding: 10px 10px 0px 10px"-->
        <!--          v-if="!noDetailt && prefix !== 'p01'"-->
        <!--          :data-id="id"-->
        <!--        />-->
        <!--        &lt;!&ndash; 项目详情 &ndash;&gt;-->
        <!--        <project-detail-->
        <!--          ref="detail"-->
        <!--          style="padding: 10px 10px 0px 10px"-->
        <!--          v-if="!noDetailt && prefix === 'p01'"-->
        <!--          :data-id="id"-->
        <!--        />-->
      </div>
    </div>
    <customTab ref="customTab" @comfirm="comfirm"> </customTab>
  </div>
</template>

<script>
import { mapState } from "vuex";
import debounce from "lodash.debounce";
import vuedraggable from "vuedraggable";
import customTab from "../header/customTab";
import {
  ViewButtons,
  ChooseView,
  SearchWrapper,
  Statistics,
} from "@/components/index";
import * as CommonObjApi from "./api.js";
import * as SetViewApi from "./api.js";
import { consoleSaveTab } from "@/views/home/api.js";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
export default {
  components: {
    ViewButtons,
    ChooseView,
    SearchWrapper,
    Statistics,
    vuedraggable,
    customTab,
  },
  data() {
    return {
      flag: true,
      // 当前选中的二级菜单的id
      currentLevel2Id: "",
      // 展示在页面中的tab数据
      aaaCopy: [],
      // 更多中展示的tab数据
      moreTabLists: [],
      // 是否显示展示更多菜单
      isShowMore: false,
      offsetWidth: 0,
      /****************************************************/
      countryCode: this.$cookies.get("countryCode"),
      skeletonOptions: {
        active: true,
        row: 15,
        column: 2,
        width: "10vw",
        height: "20px",
        justifyContent: "space-around",
      },
      id: "",
      objId: this.$route.query.objId, // 对象id
      prefix: this.$route.query.prefix, // 对象前缀
      objectApi: this.$route.query.objectApi,
      tabName: "",
      viewName: "", // 视图名称
      viewId: this.$route.query.viewId, // 视图id
      viewList: [], // 视图列表
      viewLength: 0,
      isQueryCount: false,
      searchKeyWord: "",
      conditionValues: "",
      tagIds: [], // 搜索标签
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      noDetailt: true,
      currIndex: 0,
      isCollapse: false,
      isShow: false,
      headLabelList: null,
      pageNum: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      totalSize: 0,
      pageObj: {
        dataList: [],
      },
      noDataTip: false, // 无更多数据
      sortField: this.$route.query.sortField,
      sortDir: this.$route.query.sortDir, // 排序方式
      sortFieldName: this.$route.query.sortFieldName,
      showButtons: ["liebiaoshitu", "kanbanshitu", "fenpingshitu"],
      dataListHeight: `${document.body.clientHeight - 419}px` || "100%",
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      tabStyle: "",
      exactSearchFlag: "", // 是否支持单字段搜索标识
      exactSearchFieldList: [], // 单字段搜索字段集合
      // 分屏视图项目任务跳转至新的看板任务需要的参数
      splitScreenTasksObjectApi: "",
      isShowTooltip: false, //二级菜单悬浮是否显示
    };
  },
  computed: {
    ...mapState(["consoleData"]),
    // tablist
    showTabLists(state) {
      // 二级tabList
      let level2TabList = [];
      // 当前选中的一级tab的id
      let level1Id = state.consoleData.data.level1Id;
      // 一级tablist
      let level1TabList = JSON.parse(
        JSON.stringify(state.consoleData.data.list)
      );
      // 过滤出当前一级菜单下的二级菜单列表
      level1TabList.forEach((item) => {
        if (item.id == level1Id) {
          // 当前选中一级tab下的二级tablist
          level2TabList = item.children ? item.children : [];
          // 当前一级tab下的二级选中tab的id
          this.currentLevel2Id = item.level2Id;
        }
      });
      // 往二级tab里面添加tab操作项
      // level2TabList.length > 0 &&
      //   level2TabList.forEach((i) => {
      //     // 二级下的一级菜单没有这些操作项
      //     if (i.id != level1Id) {
      //       // 处理二级tab下的操作tab的下拉
      //       i.tabAction = [
      //         {
      //           name: "刷新选项卡",
      //           id: "refresh",
      //         },
      //         {
      //           name: "自定义选项卡",
      //           id: "custom",
      //         },
      //         {
      //           name: "设置为工作区选项卡",
      //           id: "setToLevel1",
      //         },
      //         {
      //           name: "关闭选项卡",
      //           id: "close",
      //         },
      //       ];
      //     }
      //   });
      return level2TabList;
    },
  },
  created() {
    // 根据路由name判断是否更新分屏视图左侧的数据接口
    let routeNameList = ['consoleDetail','consoleProjectDetail','consolecommonObjects','服务控制台分屏页']
    if( routeNameList.includes(this.$route.name) || !this.$store.state.navigationStyle ){
      if(this.$route.query.objId){this.objId = this.$route.query.objId;}
      if (this.objId || this.consoleData.objId) {
        this.getViewList();
      }
      this.getViewListRecently();
      // 计时器计算多久前更新
      this.timer = setInterval(() => {
        // 获取当前时间
        let currentTime = new Date();
        let interval = currentTime - this.lastRefresh;

        //计算出相差天数
        var days = Math.floor(interval / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        hours = minutes > 30 ? hours + 1 : hours;
        if (days > 0) {
          // this.timeInterval = hours > 0 ? `${days}天${hours}小时前` : `${days}天前`
          this.timeInterval =
            hours > 0
              ? `${days} ${this.$i18n.t(
                  "label.login.access.day"
                )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
              : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
        } else if (hours > 0) {
          // 小时前
          this.timeInterval = `${hours} ${this.$i18n.t(
            "label_tabpage_hoursagoz"
          )}`;
        } else if (minutes > 0) {
          // 分钟前
          this.timeInterval = `${minutes} ${this.$i18n.t(
            "label_tabpage_minutesagoz"
          )}`;
        } else {
          this.timeInterval =
            seconds > 1
              ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                  seconds: seconds,
                })
              : this.$i18n.t("label.notification.time1");
        }
      }, 1000);
    }
  },
  mounted() {
    // 监听menu1.js里面发射的刷新menu1的事件
    this.$bus.$on("refreshTab2", this.refreshTab);
    // 监听menu1.js里面发射的关闭menu1的事件
    this.$bus.$on("closeTab2", this.closeTab);
    // 监听menu1.js里面发射的reopen_menu1的事件
    this.$bus.$on("clickTab2", this.clickTab);
    // 初始化数据
    if (this.showTabLists.length > 0) {
      this.init(this.showTabLists);
    }
    window.addEventListener("resize", this.windowResize);
  },
  destroyed() {
    this.changeViewFlag = false
    this.$bus.$off("removeCachePage_test", this.removeCachePageCallback);
    this.$bus.$off("clickTab2", this.clickTab);
    this.$bus.$off("refreshTab2", this.refreshTab);
    this.$bus.$off("closeTab2", this.closeTab);
    clearInterval(this.timer);
    this.timer = null
    window.removeEventListener("resize", this.windowResize);
  },
  activated() {
    if (this.showTabLists.length > 0) {
      this.init(this.showTabLists);
    }
    // 清除路由缓存的事件
    this.$nextTick(() => {
      // 打印的keep-alive组件实例在上图
      // key为路由上的唯一标识  pageId  id  page等
      if (this.$refs.routerView) {
        this.$bus.$on("removeCachePage_test", this.removeCachePageCallback );
      }
    });
  },
  methods: {
    // 计算字符串宽度（字符串，字号）
    getTextWidth(text, font) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.font = font;
      var metrics = context.measureText(text);
      return parseInt(metrics.width);
    },
    // 鼠标悬浮是否显示
    onMouseOver(value,index) {
      let parentWidth = this.$refs.remarkFather[index].offsetWidth; // 获取元素父级可视宽度
      let contentWidth = this.getTextWidth(value,"normal 14px Robot"); // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    // 删除缓存方法
    removeCachePageCallback(key) {
      /**
       * @param {Object} cache:类数组对象，存放缓冲的路由组件，键值对形，key为路由路径，value为路由组件Vnode
       * @param {Array} keys:数组，存放缓冲的路由组件的key,即路由路径
       * @param {String} key:字符串，路由组件的key，指定要删除的路由组件key值
       */
      const filterCacheByKey = (cacheList, removeKey) => {
        cacheList[removeKey]?.componentInstance?.$destroy()
        delete cacheList[removeKey]
      }
      const filterCacheKeyByKey = (cacheKeys, key) => {
        const idx = cacheKeys?.findIndex(_ => _ === key)
        idx > -1 && cacheKeys?.splice(idx,1)
      }

      let instance
      if(!this.$refs.routerView){
        const temp = this.$refs.cacheContainer?.$children
        const idx = temp?.findIndex(item => item?.$vnode?.data?.key?.includes(key))
        idx > -1 && (instance = temp[idx]?.$vnode?.parent?.componentInstance)
      }else{
        instance = this.$refs.routerView?.$vnode?.parent?.componentInstance;
        this.$refs.routerView.$destroy()
      }

      if(!instance){
        return
      }
      // if(!this.$refs.routerView){
      //   return filterParentCache(key)
      // }
      const { cache, keys } = instance,
          cacheKeys = Object.keys(cache || {}),
          removeCacheKey = cacheKeys.filter(item => item.includes(key))
      // 存在缓存对象
      if(removeCacheKey){
          filterCacheByKey(cache, removeCacheKey)
          filterCacheKeyByKey(keys, removeCacheKey)
      }
      // filterParentCache(key)
    },
    windowResize() {
      const that = this;
      if (that.showTabLists.length > 0) {
        that.init(that.showTabLists);
      }
    },
    init(showTabLists) {
      // 必须要用一个变量接收，不能直接绑定showTabLists，因为showTabLists只是展示出来的部分，还有更多下拉里面的数据，直接绑定的话showTabLists变化会直接影响store的consoleData（但此时数据不是完整的）
      this.aaaCopy = JSON.parse(JSON.stringify(showTabLists));
      // 因为要重新计算所以先清除一下
      this.moreTabLists = [];
      this.isShowMore = false;
      this.$nextTick(() => {
        // 保证dom更新后再计算  否则拿不到dom元素
        this.computedshowmenu();
      });
    },
    // 点击二级tab每项
    clickTab(item) {
      if (item.id == this.currentLevel2Id) {
        return;
      }
      // 设置当前选中tab的样式
      this.currentLevel2Id = item.id;
      this.consoleData.data.list.forEach((i) => {
        if (i.id == this.consoleData.data.level1Id) {
          i.level2Id = item.id;
        }
      });
      this.$store.commit("setConsoleData", this.$store.state.consoleData);
      // 将数据保存到后端
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
      // 有routerName说明是调用ccsdk生成的菜单
      if (item.routerName) {
        this.$router.push({
          name: item.routerName,
          params: item.params || {},
        });
      } else if (item.routerPath) {
        this.$router.push({
          path: item.routerPath,
          query: item.query || {},
        });
      } else {
        if (item.customPage == "1") {
          // 新版自定义页面
          this.$router.push({
            path: `/commonObjects/console-multi-screen/console-injection-component/${item.pageApi}/${item.id}`,
          });
        } else {
          // 标准对象或者自定义对象
          this.$router.push({
            path: `/commonObjects/console-multi-screen/console-detail/${item.id}/DETAIL`,
            query: {
              dataId: item.id,
            },
          });
        }
      }
    },
    // tab的操作
    /**
     * tabItem   当前tab对象
     * tabIndex  当前tab的下标
     * tabActionItem 当前tab的操作项
     */
    clickTabHandle(tabItem, tabIndex, tabActionItem) {
      if (tabActionItem.id == "refresh") {
        // 判断当前是选中状态再刷新
        this.refreshTab(tabItem);
      } else if (tabActionItem.id == "custom") {
        // 自定义选项卡
        this.$refs.customTab.open(tabItem);
      } else if (tabActionItem.id == "setToLevel1") {
        // 将二级tab设置为一级tab
        this.consoleData.data.list.forEach((item) => {
          // 当前选中一级tab下的children
          if (item.id == this.consoleData.data.level1Id) {
            item.children.splice(tabIndex, 1);
            // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
            if (
              item.children.length == 1 &&
              item.children[0].id == this.consoleData.data.level1Id
            ) {
              item.children = [];
            } else {
              // 重新设置二级菜单选中样式
              item.level2Id = item.children[item.children.length -1].id
            }
            // 因为二级菜单和一级菜单的操作项不一样，所以清空重新生成
            delete tabItem.tabAction;
            // 将此项添加到一级tab中
            this.consoleData.data.list.push(tabItem);
            // 设置一级菜单的选中状态
            this.consoleData.data.level1Id = tabItem.id;
            this.$store.commit("setConsoleData", this.consoleData);
            // 将数据保存到后端
            consoleSaveTab({
              appid: this.$store.state.home.homeApplicationId,
              opentab: JSON.stringify(this.consoleData),
            });
            // 有routerName或者routerPath说明是调用ccdk生成的菜单
            if (tabItem.routerName) {
              this.$router.push({
                name: tabItem.routerName,
                params: tabItem.params || {},
              });
            } else if (tabItem.routerPath) {
              this.$router.push({
                path: tabItem.routerPath,
                query: tabItem.query || {},
              });
            } else {
              // 判断当前的二级是自定义页面 还是  标准（自定义）对象
              if (tabItem.customPage == "1") {
                // 新版自定义页面
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-injection-component/${tabItem.pageApi}/${tabItem.id}`,
                });
              } else {
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-detail/${tabItem.id}/DETAIL`,
                  query: {
                    dataId: tabItem.id,
                  },
                });
              }
            }
          }
        });
      } else if (tabActionItem.id == "close") {
        // 关闭
        this.closeTab(tabItem, tabIndex);
      }
    },
    refreshTab(tabItem) {
      // 判断当前要刷新的是不是当前选中的二级tab
      this.consoleData.data.list.forEach((item) => {
        if (item.id == this.consoleData.data.level1Id) {
          if (item.level2Id == tabItem.id) {
            // 刷新
            // 有routerName或者routerPath说明是调用ccsdk生成的菜单
            if (tabItem.routerName) {
              this.$router.push({
                name: tabItem.routerName,
                params: tabItem.params || {},
                query: {
                  t: new Date().getTime(),
                },
              });
            } else if (tabItem.routerPath) {
              tabItem.query = tabItem.query || {}
              tabItem.query.t = new Date().getTime()
              this.$router.push({
                path: tabItem.routerPath,
                query: tabItem.query || {},
              });
              delete tabItem.query.t
            } else {
              // 判断当前的二级是自定义页面 还是  标准（自定义）对象
              if (tabItem.customPage == "1") {
                // 新版自定义页面
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-injection-component/${tabItem.pageApi}/${tabItem.id}`,
                });
              } else {
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-detail/${tabItem.id}/DETAIL`,
                  query: {
                    dataId: tabItem.id,
                    t: new Date(),
                  },
                });
              }
            }
          }
        }
      });
    },
    // 关闭tab
    closeTab(tabItem, tabIndex) {
      // TODO: 二级菜单关闭时，先判断是否关闭的是当前的菜单
      // TODO: 是：
      // TODO: 不是：
      // 根据当前routerView（routerView的Ref必须存在） 找 父组件 （this.$routerView.$parent.$vnode.parent.componentInstance）
      // 父组件中的 cache（keepAlive缓存对象）、keys（keepAlive缓存的key）
      // cache 调用对应要删除的组件的$destroy（item.componentInstance.$destroy()）， 删除对应的cache item
      // keys 删除对应的key
      // 注意: 要考虑存在tab但是没有keepAlive缓存的情况（多个二级菜单情况下刷新）
      const closeTabId = tabItem.id
      if(closeTabId !== this.currentLevel2Id){
        const parentInstance = this.$vnode?.parent?.componentInstance
        if(parentInstance) {
          const {cache, keys} = parentInstance
          const destroyCompKey = Object.keys(cache).filter(item => item.includes(closeTabId))[0],
          compNeedToDestroy = destroyCompKey ?  cache[destroyCompKey] : null
          keys.splice(keys.findIndex(key => key.includes(closeTabId)),1)
          if(compNeedToDestroy){
            compNeedToDestroy?.componentInstance?.$destroy()
            compNeedToDestroy.componentInstance = null
            delete cache[destroyCompKey]
          }
        }
      }else{
        // 是：
        // 清除对应的路由缓存页面
        this.removeCachePageCallback(closeTabId)
        this.$bus.$emit("removeCachePage", closeTabId);
      }

      // 清除存储localStorage
      localStorage.removeItem(tabItem.id);
      // 关闭
      this.consoleData.data.list.forEach((item) => {
        // 当前选中一级tab下的children  即二级teb列表
        if (item.id == this.consoleData.data.level1Id) {
          // 关闭的是当前选中的二级Tab
          if (tabItem.id == item.level2Id) {
            // 关闭的是最后一位
            if (tabIndex == item.children.length - 1) {
              item.children.splice(tabIndex, 1);
              // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
              if (
                item.children.length == 1 &&
                item.children[0].id == this.consoleData.data.level1Id
              ) {
                item.children = [];
              }
              // 设置当前默认选中的二级菜单
              item.level2Id = item.children[item.children.length - 1]?.id || "";
            } else {
              // 关闭的不是最后一位
              // 删除
              item.children.splice(tabIndex, 1);
              // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
              if (
                item.children.length == 1 &&
                item.children[0].id == this.consoleData.data.level1Id
              ) {
                item.children = [];
              }
              // 设置当前默认选中的二级菜单
              item.level2Id = item.children[tabIndex]?.id || "";
            }
            this.$store.commit("setConsoleData", this.consoleData);
            // 将数据保存到后端
            consoleSaveTab({
              appid: this.$store.state.home.homeApplicationId,
              opentab: JSON.stringify(this.consoleData),
            });
            // 有二级跳二级  没有二级跳当前选中的一级
            if (item.level2Id) {
              // 有二级的情况下 判断当前选中的二级tab 是自定义页面 还是 标准（自定义）对象
              // 过滤出当前选中的二级tab  (因为是关闭后重新设置的当前选中的二级Tab 所以需要过滤一下  不能用当前关闭项的tabItem)
              let level2Item = item.children.find((citem) => {
                return citem.id == item.level2Id;
              });
              // 有routerName说明是调用ccsdk生成的菜单
              if (level2Item.routerName) {
                this.$router.push({
                  name: level2Item.routerName,
                  params: level2Item.params || {},
                });
              } else if (level2Item.routerPath) {
                this.$router.push({
                  path: level2Item.routerPath,
                  query: level2Item.query || {},
                });
              } else {
                // 判断当前的二级是自定义页面 还是  标准（自定义）对象
                if (level2Item.customPage == "1") {
                  // 新版自定义页面
                  this.$router.push({
                    path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                  });
                } else {
                  this.$router.push({
                    path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
                    query: {
                      dataId: item.level2Id,
                    },
                  });
                }
              }
            } else {
              // 二级都关闭之后 跳转当前选中的一级tab
              // 还需要判断当前的一级tab是否是通过ccdk添加的
              if (item.routerName) {
                this.$router.push({
                  name: item.routerName,
                  params: item.params || {},
                });
              } else if (item.routerPath) {
                this.$router.push({
                  path: item.routerPath,
                  query: item.query || {},
                });
              } else {
                this.$router.push({
                  path: `/commonObjects/console-multi-screen/console-detail/${this.consoleData.data.level1Id}/DETAIL`,
                  query: {
                    dataId: this.consoleData.data.level1Id,
                  },
                });
              }
            }
          } else {
            // 判断有没有要关闭的这条数据 没有要关闭的这条数据直接return  解决bug4078
            let res = item.children.some((c) => {
              return c.id == tabItem.id;
            });
            if (!res) {
              return
            }
            // 关闭的不是当前选中的直接关闭即可
            item.children.splice(tabIndex, 1);
            // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
            if (
              item.children.length == 1 &&
              item.children[0].id == this.consoleData.data.level1Id
            ) {
              item.children = [];
            }
            this.$store.commit("setConsoleData", this.consoleData);
            // 将数据保存到后端
            consoleSaveTab({
              appid: this.$store.state.home.homeApplicationId,
              opentab: JSON.stringify(this.consoleData),
            });
          }
          // 在派工平台下关闭二级菜单
          if(item.routerName == "consoleDispatchingUnits"){
            this.$bus.$emit("setConsoleDispatchingUnits")
          } 
        }
      });
      // 清除对应的路由缓存页面
      this.removeCachePageCallback(tabItem.id)
      this.$bus.$emit("removeCachePage", tabItem.id);
    },
    // 修改自定义选项卡dialog 点击确定之后
    comfirm(tabObj) {
      this.consoleData.data.list.forEach((item) => {
        // 当前选中一级tab下的children
        if (item.id == this.consoleData.data.level1Id) {
          item.children.forEach((cItem, cIndex) => {
            if (cItem.id === tabObj.id) {
              item.children.splice(cIndex, 1, tabObj);
            }
          });
        }
      });
      this.$store.commit("setConsoleData", this.consoleData);
    },
    // tab拖拽结束
    tabDragEnd() {
      // 拖拽不需要刷新
      this.flag = false;
      // 过滤出当前一级菜单下的二级菜单列表
      this.consoleData.data.list.forEach((item) => {
        if (item.id == this.consoleData.data.level1Id) {
          item.children = [...this.aaaCopy, ...this.moreTabLists];
        }
      });
      this.$store.commit("setConsoleData", this.consoleData);
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
    },
    //计算当前浏览器窗口大小所展示的菜单数量
    //计算当前浏览器窗口大小所展示的菜单数量
    computedshowmenu: debounce(function () {
      let arr = [];
      let arrIndex = 0; // 超出盒子宽度的下标
      // box
      let box = document.getElementById("twoBox");
      let num = box?.childNodes.length;
      // 计算出每个元素渲染后dom的宽度
      for (let i = 0; i < num; i++) {
        // 当前按钮宽度 + 当前按钮距离浏览器左边的宽度
        let weight =
          box?.childNodes[i].offsetWidth + box?.childNodes[i].offsetLeft;
        arr.push(weight);
      }
      let boxWidth;
      // 盒子的宽度
      if (this.isShow) {
        boxWidth =
                document.documentElement.clientWidth - 400;
      } else {
        boxWidth =
                document.documentElement.clientWidth - 20;
      }

      // 更多的宽度
      let moreWidth = 200;
      // 计算出第几个元素渲染后盒子就装不下了,返回对应元素的索引,都不满足条件返回-1
      arrIndex = arr.findIndex((res) => res > boxWidth - moreWidth);
      if (arrIndex != -1) {
        //  装不下
        this.moreTabLists = this.aaaCopy.splice(
          arrIndex,
          arr.length - arrIndex
        );
        this.isShowMore = true;
      } else {
        // 能装下
        this.moreTabLists = [];
        this.isShowMore = false;
      }
    }, 0),
    //计算菜单字符串长度
    strlen(str) {
      let len = 0;
      let copystr = str || "";
      // 菜单最多展示8个汉字长度
      if (copystr.length > 8) {
        copystr = copystr.substring(0, 7);
      }
      for (let i = 0; i < copystr.length; i++) {
        let c = copystr.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
          len += 2;
        }
      }
      return len;
    },
    /**
     * 一级tab点击更多
     */
    clickmoreTabs(item, index) {
      this.flag = false;
      // 展示菜单的最后一项拿出来
      let lastItem = this.aaaCopy.splice(this.aaaCopy.length - 1, 1);
      // 将点击的这项从更多中截取掉
      this.moreTabLists.splice(index, 1);
      // 将展示菜单的最后一项放到更多中
      this.moreTabLists.unshift(...lastItem);
      // 把点击的这项放到展示菜单的最后一个
      this.aaaCopy.push(item);
      // 过滤出当前一级菜单下的二级菜单列表
      this.consoleData.data.list.forEach((item) => {
        if (item.id == this.consoleData.data.level1Id) {
          item.children = [...this.aaaCopy, ...this.moreTabLists];
        }
      });
      this.$store.commit("setConsoleData", this.consoleData);
      consoleSaveTab({
        appid: this.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(this.consoleData),
      });
      this.clickTab(item);
    },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    active(index) {
      this.currIndex = index;
    },
    handleOpen() {},
    handleClose() {},
    // 切换视图
    changeView(view) {
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label;
          this.viewId = item.id;
          item.isCurrent = true;
        } else {
          item.isCurrent = false;
        }
      });

      // 刷新视图列表
      this.refresh();
      // 按钮权限控制
      this.ismodify();
    },
    // 获取视图列表
    getViewList() {
      this.viewName = "";
      let objInfo = {viewId: this.viewId, objId: this.objId}
      // 控制台应用程序永远取this.consoleData
      if(this.$store.state.navigationStyle){
        objInfo.objId = this.consoleData.objId
        objInfo.viewId = this.consoleData.viewId
      }
      SetViewApi.GetViewList({
        objId: objInfo.objId,
      }).then((res) => {
        this.viewList = res.data;
        this.viewList.map((item) => {
          // 获取列表视图/看板视图带过来的视图信息
          if (item.id === objInfo.viewId ) {
            item.isCurrent = true;
            this.viewName = item.label;
          } else {
            item.isCurrent = false;
          }
        });
        this.viewLength = this.viewList.length;
        this.getViewListHeader();
        this.getViewListData();
        // 按钮权限控制
        this.ismodify();
      });
    },
    // 获取视图列表按钮权限
    getViewListRecently() {
      this.exactSearchFlag = "";
      this.exactSearchFieldList = [];
      let param = {
        obj: this.prefix,
        viewId: this.viewId || '1',
      }
      if(this.$store.state.navigationStyle){
        param.obj = this.consoleData.prefix
        param.viewId = this.consoleData.viewId
      }
      CommonObjApi.getViewListRecently(param).then((res) => {
        this.tabStyle = res.data.tabStyle;
        this.tabName = res.data.label;
        this.exactSearchFlag = res.data.exactSearchFlag || "";
        this.exactSearchFieldList = res.data.exactSearchFieldList || [];
        // 是否启用查找统计
        this.isQueryCount = res.data.isQueryCount;
      });
    },
    // 获取视图列表表头
    getViewListHeader() {
      let param = {
        obj: this.prefix,
        viewId: this.viewId || '1',
      }
      if(this.$store.state.navigationStyle){
        param.obj = this.consoleData.prefix
        param.viewId = this.consoleData.viewId
      }
      CommonObjApi.getViewListHeader(param).then((res) => {
        res.data.headLabel.forEach((head, idx) => {
          if (head.schemefieldType === "X") {
            res.data.headLabel.splice(idx, 1);
          }
          // 潜在客户评分
          if (head.schemefieldName === "leadscore") {
            this.getLeadHeat();
          }
        });
        this.headLabelList =
          res.data.headLabel.length > 3
            ? res.data.headLabel.slice(0, 4)
            : res.data.headLabel;
      });
    },
    // 新版服务控制台初始化一进来的tab状态
    setDefaultTab() {
      let path = "";
      // 路由中有参数的时候
      if (this.$route.query.rowId) {
        // 说明是从视图列表中跳过来的  点击视图列表跳转会走这里
        // 有一级菜单跳转一级菜单 没有跳转二级
        // 当前选中的一级tab
        let level1Id = this.consoleData.data.level1Id;
        // 过滤出当前一级菜单下的二级菜单列表
        this.consoleData.data.list.forEach((item) => {
          if (item.id == level1Id) {
            if (item.children && item.children.length > 0) {
              // 有children说明有二级tab 那么跳转到当前选中的二级tab
              // 过滤出当前选中的二级item  用于判断是自定义页面 还是 标准（自定义）对象 跳转不同的路由
              let level2Item = item.children.find((citem) => {
                return citem.id == item.level2Id;
              });
              if (level2Item.routerName) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  name: level2Item.routerName,
                  params: level2Item.params || {},
                });
                return;
              } else if (level2Item.routerPath) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  path: level2Item.routerPath,
                  query: level2Item.query || {},
                });
                return;
              } else {
                if (level2Item.customPage == "1") {
                  // 新版自定义页面
                  // 自定义页面的id 和 name都是pageApi  所以默认列表第一条
                  this.id = this.pageObj.dataList[0].id;
                  path = `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`;
                } else {
                  this.id = item.level2Id;
                  path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
                }
              }
            } else {
              // 没有二级tab 那么跳转到当前选中的一级tab
              if (item.routerName) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  name: item.routerName,
                  params: item.params || {},
                });
                return;
              } else if (item.routerPath) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  path: item.routerPath,
                  query: item.query || {},
                });
                return;
              } else {
                this.id = this.$route.query.rowId;
                path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
              }
            }
          }
        });
      } else {
        // 分屏视图详情页面刷新的时候会走这里  没有rowId
        // list大于0可以初步判断有一级tab
        if (this.consoleData.data.list.length > 0) {
          // 当前选中的一级tab
          let level1Id = this.consoleData.data.level1Id;
          // 过滤出当前一级菜单下的二级菜单列表
          this.consoleData.data.list.forEach((item) => {
            if (item.id == level1Id) {
              if (item.children && item.children.length > 0) {
                // 有children说明有二级tab 那么跳转到当前选中的二级tab
                // 过滤出当前选中的二级item  用于判断是自定义页面 还是 标准（自定义）对象 跳转不同的路由
                let level2Item = item.children.find((citem) => {
                  return citem.id == item.level2Id;
                });
                if (level2Item.routerName) {
                  this.id = this.pageObj.dataList[0].id;
                  this.$router.push({
                    name: level2Item.routerName,
                    params: level2Item.params || {},
                  });
                  return;
                } else if (level2Item.routerPath) {
                  this.id = this.pageObj.dataList[0].id;
                  this.$router.push({
                    path: level2Item.routerPath,
                    query: level2Item.query || {},
                  });
                  return;
                } else {
                  if (level2Item.customPage == "1") {
                    // 新版自定义页面
                    // 自定义页面的id 和 name都是pageApi  所以默认列表第一条
                    this.id = this.pageObj.dataList[0].id;
                    path = `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`;
                  } else {
                    this.id = item.level2Id;
                    path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
                  }
                }
              } else {
                // 没有二级tab 那么跳转到当前选中的一级tab
                if (item.routerName) {
                  this.id = this.pageObj.dataList[0].id;
                  this.$router.push({
                    name: item.routerName,
                    params: item.params || {},
                  });
                  return;
                } else if (item.routerPath) {
                  this.id = this.pageObj.dataList[0].id;
                  this.$router.push({
                    path: item.routerPath,
                    query: item.query || {},
                  });
                  return;
                } else {
                  this.id = level1Id;
                  path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
                }
              }
            }
          });
        } else {
          this.id = this.pageObj.dataList[0].id;
          path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
        }
      }
      if (path) {
        this.$router.push({
          path: path,
        });
      }
    },
    /**
     * 判断是否是ccdk打开分屏详情页,并且没有修改视图（解决ccdk打开分屏视图prefix和viewId不一致的问题）
     */
    isOpenDetailPage(){

      return !this.$route.query.viewId && !this.changeViewFlag
    },
    // 获取视图数据
    getViewListData() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */

      this.noDataTip = false;
      let getViewListDataParams = {
        obj: this.prefix,
        viewId: this.viewId || '1',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortDir,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
        ischangenum: "false",
        recordnums: this.pageSize,
        tagIds: this.tagIds.toString(),
        isAllTag: this.isAllTag,
        campaignid: "false",
        isallsearch: this.isallsearch,
        myself: this.myself,
        scrollId: this.scrollId,
        type: "",
        exactSearchFlag: this.exactSearchFlag,
      };
      // 控制台应用程序永远取this.consoleData
      if(this.$store.state.navigationStyle){
        getViewListDataParams.obj = this.consoleData.prefix
        getViewListDataParams.viewId = this.consoleData.viewId
      }
      CommonObjApi.getViewListData(getViewListDataParams).then((res) => {
        this.totalSize = res.data.totalSize;
        if (this.pageObj.dataList.length === 0 || this.pageNum === 1) {
          this.pageObj.dataList = res.data.list;
          // setTimeout(() => {
          //   this.pageNum++;
          // }, 600);
          this.pageNum++;
          // 分屏视图右侧默认展示左侧列表第一条数据
          // this.id = this.$route.query.rowId || this.pageObj.dataList[0].id;
          this.noDetailt = false;
          this.setDefaultTab();
          // this.$nextTick(() => {
          //   this.$router.push({
          //     path: `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL`,
          //     query: {
          //       dataId: this.id,
          //     },
          //   });
          //   // this.$refs.detail.init();
          // });
        } else {
          this.pageObj.dataList = [...this.pageObj.dataList, ...res.data.list];
          this.pageNum++;
        }
        // 判断是否有更多数据
        if (res.data.haveMore === "false") {
          this.noDataTip = true;
        }
        if (this.id === "") {
          // 分屏视图右侧默认展示左侧列表第一条数据
          this.id = this.$route.query.rowId || this.pageObj.dataList[0].id;
          this.noDetailt = false;
          this.lastRefresh = new Date();
        }
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动加载
    scrollDataList() {
      let elem = document.getElementsByClassName("dataList")[0];

      if (elem.scrollHeight - elem.scrollTop - elem.clientHeight <= 1) {
        if (!this.noDataTip) {
          this.fnThrottle(this.getViewListData, 500, 1000)();
        }
      }
    },
    // 判断对象数组中是否包含某一项 arr数组  itemId字符串
    isHasItem(arr, itemId) {
      let res = arr.some((item) => {
        if (item.id == itemId) {
          return true;
        } else {
          return false;
        }
      });
      return res;
    },
    // 数据详情
    showDetail(data) {
      // todo
      let Level1TabItem = {};
      Level1TabItem.id = data.id;
      // 任务对象视图列表没有name  是subject
      Level1TabItem.name = data.name || data.subject;
      // 设置当前选中tab的样式
      this.consoleData.data.level1Id = data.id;
      // 判断是否存在此点击项
      let res = this.isHasItem(this.consoleData.data.list, data.id);
      if (!res) {
        // 如果不存在
        this.consoleData.data.list.push(Level1TabItem);
        this.$store.commit("setConsoleData", this.consoleData);
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
      this.noDetailt = false;
      if (this.prefix !== "p01") {
        // 如果当前一级菜单下有选中的二级菜单打开二级菜单  没有二级菜单打开一级菜单
        // 当前选中的一级tab
        let path = ""
        let level1Id = this.consoleData.data.level1Id;
        // 过滤出当前一级菜单下的二级菜单列表
        this.consoleData.data.list.forEach((item) => {
          if (item.id == level1Id) {
            if (item.children && item.children.length > 0) {
              // 有children说明有二级tab 那么跳转到当前选中的二级tab
              // 过滤出当前选中的二级item  用于判断是自定义页面 还是 标准（自定义）对象 跳转不同的路由
              let level2Item = item.children.find((citem) => {
                return citem.id == item.level2Id;
              });
              if (level2Item.routerName) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  name: level2Item.routerName,
                  params: level2Item.params || {},
                });
                return;
              } else if (level2Item.routerPath) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  path: level2Item.routerPath,
                  query: level2Item.query || {},
                });
                return;
              } else {
                if (level2Item.customPage == "1") {
                  this.id = this.pageObj.dataList[0].id;
                  // 新版自定义页面
                  // 自定义页面的id 和 name都是pageApi  所以默认列表第一条
                  path = `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`;
                } else {
                  this.id = item.level2Id;
                  path = `/commonObjects/console-multi-screen/console-detail/${level2Item.id}/DETAIL?dataId=${level2Item.id}`;
                }
              }
            } else {
              // 没有二级tab 那么跳转到当前选中的一级tab
              if (item.routerName) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  name: item.routerName,
                  params: item.params || {},
                });
                return;
              } else if (item.routerPath) {
                this.id = this.pageObj.dataList[0].id;
                this.$router.push({
                  path: item.routerPath,
                  query: item.query || {},
                });
                return;
              } else {
                this.id = data.id;
                path = `/commonObjects/console-multi-screen/console-detail/${this.id}/DETAIL?dataId=${this.id}`;
              }
            }
          }
        });
        this.$router.push({
          path: path,
        });
      } else {
        this.$router.push({
          path: `/commonObjects/console-multi-screen/console-project-detail/${data.id}/DETAIL`,
          query: {
            dataId: data.id,
          },
        });
      }
      // this.$nextTick(() => {
      //   this.$refs.detail.init();
      //   this.$refs.detail.multiTask();
      // });
    },
    // 列表视图
    tableView() {
      //知识文章列表
      if (this.objectApi == "CloudccKArticle") {
        this.$router.push({
          path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
          query: { viewId: this.viewId || this.consoleData.viewId },
        });
      } else {
        this.$router.push({
          path: `/commonObjects/views/table/${
            this.objectApi || this.consoleData.objectApi
          }/${this.objId || this.consoleData.objId}/${
            this.prefix || this.consoleData.prefix
          }`,
          query: { viewId: this.viewId || this.consoleData.viewId },
        });
      }
    },
    // 看板视图
    boardView() {
      // 项目任务跳转百分比页面
      if (this.splitScreenTasksObjectApi === "cloudccTask") {
        let tasjId = "ffe20201207task09";
        localStorage.setItem("key", tasjId);
        this.$router.push({
          path: `/projectTasks`,
        });
      } else {
        let routeParan ={
          path: `/commonObjects/views/kanban/${
            this.objectApi
          }/${this.objId }/${
            this.prefix
          }`,
          query: { viewId: this.viewId },
        }
        // 控制台应用程序永远取this.consoleData
        if(this.$store.state.navigationStyle){
          routeParan ={
            path: `/commonObjects/views/kanban/${
              this.consoleData.objectApi
            }/${ this.consoleData.objId}/${
              this.consoleData.prefix
            }`,
            query: { viewId: this.consoleData.viewId },
          }
        }
        this.$router.push(routeParan);
      }
    },
    // 轮班计划视图
    planView() {
      let routeParan ={
        path: `/commonObjects/views/plan/${
          this.objectApi
        }/${this.objId}/${
          this.prefix
        }`,
        query: {
          viewId: this.viewId,
        },
      }
        // 控制台应用程序永远取this.consoleData
        if(this.$store.state.navigationStyle){
          routeParan ={
            path: `/commonObjects/views/plan/${
               this.consoleData.objectApi
            }/${this.consoleData.objId}/${
              this.consoleData.prefix
            }`,
            query: {
              viewId:  this.consoleData.viewId,
            },
          }
        }
      this.$router.push(routeParan);
    },
    // 刷新
    refresh() {
      this.sortField = "";
      this.sortFieldName = "";
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;

      this.getViewListRecently();
      this.getViewListHeader();
      this.fnThrottle(this.getViewListData, 500, 1000)();
    },
    // 搜索条件改变
    changeKeyWord(val) {
      this.pageNum = 1;
      this.pageObj.dataList = [];
      this.searchKeyWord = val;
      this.getViewListData();
    },
    // 控制左侧内容显示/隐藏
    toggleLeft() {
      this.isShow = !this.isShow;
      this.init(this.showTabLists);
      // this.isCollapse = !this.isCollapse
    },
    // 看板视图仅在非四个特殊视图下展示
    ismodify() {
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        this.showButtons = ["liebiaoshitu", "fenpingshitu"];
      } else {
        this.showButtons = ["liebiaoshitu", "kanbanshitu", "fenpingshitu"];
      }
    },
  },
  watch: {
    showTabLists: {
      handler: function (newVal, oldVal) {
        if (!this.flag) {
          this.flag = true;
          return;
        }
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.init(newVal);
        }
      },
      deep: true,
    },
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.splitScreenTasksObjectApi = route.params.objectApi;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
i {
  cursor: pointer;
}
/* 评分字段显示 */
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  /* margin-left: 10px; */

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
    height: 100%;
    position: relative;
    /* padding: 10px 26px 0 0; */
    padding: 10px 12px 0 0;
  }

  ::v-deep .el-menu--collapse {
    width: 17px;
    height: 100%;
  }

  .sort-content {
    clear: both;
    font-size: 12px;
    border-top: 1px solid #dddbda;
    border-bottom: 1px solid #dddbda;
    padding-left: 20px;
    width: calc(100% - 17px);
    height: 40px;
    line-height: 40px;
    color: #333;

    .icon-sort {
      height: 12px;
      vertical-align: "";
      display: inline-block;
    }

    span {
      color: #666;
      margin-left: 18px;
    }
  }

  .icon-box {
    position: absolute;
    top: 0;
    right: 1px;
    height: 100%;
    width: 12px;
    border: 1px solid #ccc;
    cursor: pointer;
    background: #fff;
    box-shadow: -1px 0 1px #ccc;
    /* box-shadow: 0 1px 2px 1px #ccc; */

    .icon {
      position: absolute;
      top: 50%;
      left: -1px;
      font-size: 12px;
      color: #666;
    }
  }

  .editableCellBox {
    width: calc(33.33% - 17px) !important;
  }

  .dataList {
    width: calc(100% - 13px);
    height: calc(100% - 185px);
    /* padding-right: 6px; */
    overflow: hidden;
    margin-bottom: 0;
    &:hover {
      overflow: auto;
    }

    .cannotEdit {
      .tag {
        margin: 3px;
      }
    }

    .data-box {
      width: 94%;
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #dddbda;

      &:hover,
      &:active {
        background: #fff;
      }

      .data-item {
        width: 50%;
        min-height: 18px;
        /* line-height: 32px; */
        font-size: 12px;
        /* min-height: 18px; */
        /* height: auto; */
        color: #666;
      }

      .text-align-right {
        text-align: right;
      }
    }
  }

  .left-content {
    width: 400px;
    height: 100%;
    border-radius: 10px;
    float: left;
    overflow: hidden;
  }
  .right-content1 {
    width: 100%;
    height: calc(100% - 50px)!important;
    overflow: auto;
  }
  .right-content {
    width: 100%;
    height: calc(100% - 2px);
    overflow: auto;
  }

    .noDetailt {
      width: calc(100% - 1px);
      height: 100%;
      /* padding-top: calc(50% - 153px); */
      text-align: center;
      background: #fff;
      position: relative;
      left: 1px;
      top: calc(50% - 153px);

      .icon-noData {
        width: 100%;
        height: 264px;
      }
    }
  

  .choose-view {
    width: 100%;
    margin: 10px 0 12px 10px;
  }

  .searchWrapper {
    margin-bottom: 20px;
    /* 分屏视图情况下距离上方距离 */
    margin-top: 10px;
  }

  .viewButtons {
    position: relative;
    /* top: -14px; */
    height: 28px;
    margin: 0 23px 0 0;

    .line-two-btn .group-btn {
      margin-top: 0;
      background-size: 56%;
      background-position-x: 6px;
    }
  }

  .statistics {
    clear: both;
    float: left;
    margin-left: 10px;

    .title {
      margin-bottom: 10px;
      /* 防止过长影响右侧按钮，后期需要改为flex布局 */
      max-width: 240px;
    }
  }
}
.menu-tooltip{
  background-color: #ffffff !important;
  max-width: 500px;
  border-radius: 0;
  padding: 6px;
}
</style>
<style lang="scss" scoped>
.tabWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: #ffffff;
  // background: #f0f0f0;
}
.selectedTab-text {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  text-decoration: none !important;
}
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}
h3 {
  margin: 0;
  padding: 0;
  list-style: none;
}
::v-deep .mymore-menus {
  ::-webkit-scrollbar {
    width: 3px; /*对垂直流动条有效*/
    height: 84px; /*对水平流动条有效*/
    background: #20233a;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #abc4fe;
  }
  ::-webkit-scrollbar-track {
    background-color: #20233a;
    border-radius: 9px;
  }
}
::v-deep.el-popper .popper__arrow::after {
  border-width: 0;
}
::v-deep.el-popper .popper__arrow {
  border-width: 0;
}
::v-deep .moreMenus {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;
  color: #fff;
  min-width: 70px;
  /*line-height: 46px;*/
  .more-menusbox {
    > span,
    i {
      color: #333333;
    }
  }
  .more-menusbox:hover {
    color: #abc4fe !important;
    span,
    i {
      color: #333333;
    }
  }
}
.editmenu {
  padding-right: 10px;
  cursor: pointer;
  span {
    font-size: 20px !important;
  }
}

.topContent {
  width: 100%;
  height: 48px;
  position: relative;
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .topContent_left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .topcontentmenu {
      max-width: 145px;
      overflow: hidden;
      white-space: nowrap;
      span {
        color: #fff;
      }
    }
    .data_left {
      width: 155px;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #080707;
      img {
        width: 21px;
        height: 21px;
        margin-right: 24px;
      }
    }
    .data_menu {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      user-select: none;
      /*height: 46px;*/
      /*display: flex;*/
      font-size: 14px;
      // padding-top: 2px;
      .each_menu {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: #333333;
        padding: 0 5px;
        cursor: pointer;
        background: #ffffff;
        i {
          color: #333333;
        }
        span {
          display: flex;
          align-items: center;
          // padding: 4px 2px;
          // border: 2px #f0f0f0 solid;
          a {
            max-width: 70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: center;
            height: 48px;
            line-height: 48px;
          }
          a:hover {
            color: #abc4fe !important;
          }
        }
      }
      ::v-deep .el-popper {
        margin-top: 0;
      }
      ::v-deep .el-button--medium {
        border-radius: 0 !important;
      }
      ::v-deep .el-button {
        border-radius: 0 !important;
      }
    }
  }

  .cur-menu {
    background: #e8f4ff !important;
    // border-bottom: 2px solid #abc4fe !important;
  }

  .topContent_right {
    position: relative;
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #080707;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      &:hover {
      }
    }
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.link-text-color-style {
  li {
    a {
      color: #000 !important;
      font-size: 14px;
      display: inline-block;
      max-width: 150px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.link-dropdown-style {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  color: #333333;
  &:hover {
    color: #333333;
  }
}
.link-dropdown-style:hover {
  background: rgba(238,238,238,0.9);
  border-radius: 3px;
}
::v-deep.el-dropdown {
  color: #ffffff;
}
.menus-show-all {
  overflow: auto;
}
.menus-show-hidden {
  overflow: hidden;
}
.more-menus-fixed {
  max-height: 260px;
}
</style>
